<template>
  <section class="section section_program">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="title title_dark title_second title_svg title_svg-show">Трансляция конференции
            <router-link to="/">
              <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_226_668)">
                  <circle cx="19" cy="18" r="18" transform="rotate(-90 19 18)" fill="#59B980"/>
                </g>
                <path
                    d="M15.779 27.0592L16.0039 27.1096L16.1883 26.9713L24.3651 20.8412L24.3659 20.8406C25.6264 19.8918 26.3574 18.7758 26.3574 17.5713C26.3574 16.3668 25.6264 15.2508 24.3659 14.302L24.3651 14.3014L16.1883 8.17123L16.0039 8.03298L15.779 8.0834L15.4623 8.15441L14.4039 8.3917L15.2718 9.04235L23.4482 15.1723C24.5481 15.9984 24.9975 16.8291 24.9975 17.5713C24.9975 18.3135 24.5482 19.1441 23.4484 19.9702C23.4483 19.9702 23.4482 19.9703 23.4482 19.9703L15.2718 26.1002L14.4039 26.7509L15.4623 26.9882L15.779 27.0592Z"
                    fill="white" stroke="white"/>
                <defs>
                  <filter id="filter0_d_226_668" x="0" y="0" width="38" height="38" filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="1"/>
                    <feGaussianBlur stdDeviation="0.5"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix"
                                   values="0 0 0 0 0.34902 0 0 0 0 0.72549 0 0 0 0 0.501961 0 0 0 0.77 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_226_668"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_226_668" result="shape"/>
                  </filter>
                </defs>
              </svg>
            </router-link>
          </h2>

        </div>
      </div>
      <div class="row pt-4">
        <div class="col-12 col-lg-3">
          <div class="program" v-for="day in getTelecastingWithSection" :key="day.id">
            <div class="program__items" v-for="section in day.sections" :key="section.id">
              <div class="program__head" @click="changeActive(section.id)"
                   :class="section.id === active ? 'active' : ''">
                <div class="container">
                  <div class="row">
                    <div class="col-12">
                      <div class="program__area">
                        {{ section.name }}
                      </div>
                      <div class="program__themes">
                        {{ section.theme }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pt-5 d-none d-lg-block">
            <rasp-slider></rasp-slider>
          </div>
        </div>
        <div class="col-12 col-lg-9">
          <telecast-component :telecast="getActiveTelecast" v-if="active"></telecast-component>
          <div class="pt-3 d-block d-lg-none">
            <rasp-slider></rasp-slider>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import _ from "lodash";
import TelecastComponent from "../components/Telecast/TelecastComponent";
import RaspSlider from "../components/components/RaspSlider";

export default {
  name: "Telecast",
  components: {RaspSlider, TelecastComponent},
  data: () => ({
    telecast: [{
      "id": 1,
      "link": "https://facecast.net/v/k5xj02",
      "preview": "/images/astra_bg.jpg",
      "hasChat": false,
      "createdAt": "2022-04-11T20:19:42.000Z",
      "updatedAt": "2022-04-22T19:14:25.000Z",
      "day_id": 2,
      "section_id": 1,
      "programSection": {
        "id": 1,
        "name": "Пленарная сессия",
        "theme": "На пути к глобальной ИТ-трансформации",
        "order": 1,
        "createdAt": "2022-03-14T11:55:29.000Z",
        "updatedAt": "2022-03-14T11:55:29.000Z"
      },
      "programDay": {
        "id": 2,
        "name": "День 1",
        "createdAt": "2022-03-14T11:55:01.000Z",
        "updatedAt": "2022-03-14T11:55:01.000Z"
      }
    }, {
      "id": 2,
      "link": "https://facecast.net/v/catgql?noanimation&nolabel",
      "preview": "/images/astra_bg.jpg",
      "hasChat": false,
      "createdAt": "2022-04-11T20:22:30.000Z",
      "updatedAt": "2022-04-22T19:12:46.000Z",
      "day_id": 2,
      "section_id": 2,
      "programSection": {
        "id": 2,
        "name": "Секция 1",
        "theme": "Практика импортозамещения",
        "order": 2,
        "createdAt": "2022-03-14T11:58:59.000Z",
        "updatedAt": "2022-04-02T18:52:17.000Z"
      },
      "programDay": {
        "id": 2,
        "name": "День 1",
        "createdAt": "2022-03-14T11:55:01.000Z",
        "updatedAt": "2022-03-14T11:55:01.000Z"
      }
    }, {
      "id": 3,
      "link": "https://facecast.net/v/76bbjj?noanimation&nolabel",
      "preview": "/images/astra_bg.jpg",
      "hasChat": false,
      "createdAt": "2022-04-11T20:24:02.000Z",
      "updatedAt": "2022-04-23T18:32:09.000Z",
      "day_id": 2,
      "section_id": 3,
      "programSection": {
        "id": 3,
        "name": "Секция 2",
        "theme": "ИТ-решения для цифровизации",
        "order": 3,
        "createdAt": "2022-03-14T11:59:20.000Z",
        "updatedAt": "2022-04-02T18:52:18.000Z"
      },
      "programDay": {
        "id": 2,
        "name": "День 1",
        "createdAt": "2022-03-14T11:55:01.000Z",
        "updatedAt": "2022-03-14T11:55:01.000Z"
      }
    }, {
      "id": 4,
      "link": "https://facecast.net/v/d254mu?noanimation&nolabel",
      "preview": "/images/astra_bg.jpg",
      "hasChat": false,
      "createdAt": "2022-04-11T20:25:17.000Z",
      "updatedAt": "2022-04-22T19:13:00.000Z",
      "day_id": 2,
      "section_id": 4,
      "programSection": {
        "id": 4,
        "name": "Секция 3",
        "theme": "Как это работает: демонстрация решений",
        "order": 4,
        "createdAt": "2022-03-14T11:59:41.000Z",
        "updatedAt": "2022-04-02T18:52:19.000Z"
      },
      "programDay": {
        "id": 2,
        "name": "День 1",
        "createdAt": "2022-03-14T11:55:01.000Z",
        "updatedAt": "2022-03-14T11:55:01.000Z"
      }
    }, {
      "id": 5,
      "link": "https://facecast.net/v/bxb394?noanimation&nolabel",
      "preview": "/images/astra_bg.jpg",
      "hasChat": false,
      "createdAt": "2022-04-11T20:26:01.000Z",
      "updatedAt": "2022-04-22T19:13:07.000Z",
      "day_id": 2,
      "section_id": 5,
      "programSection": {
        "id": 5,
        "name": "Секция 4",
        "theme": "Система воспроизводства ИТ-кадров",
        "order": 5,
        "createdAt": "2022-03-14T12:00:01.000Z",
        "updatedAt": "2022-04-14T12:59:18.000Z"
      },
      "programDay": {
        "id": 2,
        "name": "День 1",
        "createdAt": "2022-03-14T11:55:01.000Z",
        "updatedAt": "2022-03-14T11:55:01.000Z"
      }
    }],
    active: null,
  }),
  methods: {
    changeActive(id) {
      this.active = id
    }
  },
  computed: {
    getSections() {
      return this.$store.getters.getProgram
    },
    getQuerySection() {
      return this.$route.query.section
    },
    getTelecastingWithSection() {
      if (this.telecast.length) {
        let arr = []
        _.forEach(this.getSections, (day) => {
          let dayToArr = {
            id: day.id,
            name: day.name,
            order: day.order,
            sections: []
          }
          _.forEach(day.sections, (section) => {
            let sectionToArr = {
              id: section.id,
              name: section.name,
              theme: section.theme,
              order: section.order
            }
            sectionToArr.telecast = _.find(this.telecast, telecast => telecast.section_id === section.id && telecast.day_id === day.id).id || undefined
            dayToArr.sections.push(sectionToArr)
          })
          arr.push(dayToArr)
        })
        return arr
      }
      return []
    },
    getActiveSection() {
      return _.find(this.getSections[0].sections, section => section.id === this.active)
    },
    getActiveTelecast() {
      return _.find(this.telecast, telecast => telecast.section_id === this.active)
    }
  },
  watch: {
    getTelecastingWithSection(val) {
      this.active = parseInt(this.$route.query.section) || val[0].sections[0].id
    },
    getQuerySection(val) {
      if (val) {
        this.active = val
      }
    }
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">
.program {

  &__head {
    background: rgba(89, 185, 128, 0.06);
    border: 1px solid rgba(89, 185, 128, 0.51);
    box-sizing: border-box;
    border-radius: 4px;
    padding: rem(10) 0;
    font-size: rem(16);
    font-weight: 300;
    cursor: pointer;
    min-height: 94px;
    display: flex;
    align-items: center;
    margin-bottom: rem(5);
    @include media(992) {
      min-height: 76px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.active {
      background: #52B5E0;
      color: #fff;
    }

  }

  &__area {
    text-transform: lowercase;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  &__themes {
    //text-transform: lowercase;
    font-weight: bold;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  &__time {
    color: #00476D;
    font-size: rem(24);
    font-weight: bold;
  }

  &__text {

  }

  &__item {
    border-bottom: 2px solid #ECECF1;

  }

  &__h {
    padding: rem(15) 0;
    cursor: pointer;
    position: relative;
    @include media(768) {
      svg {
        position: absolute;
        top: 20px;
        right: 10px;
      }

    }
  }

  &__name {
    font-size: rem(18);
    font-weight: 300;
    color: #282938;
  }

  &__text {
    background: #FFFBF2;
    border-radius: 10px;
    padding: rem(10) 0;
    margin-bottom: rem(10);
  }

  &__theme {
    &::v-deep {
      ul {
        padding: 0;
        margin: 0;
      }

      @include media(768) {
        padding: 0 20px;
      }
    }
  }


}
</style>
