<template>
  <div v-if="getProgram.length">
    <div class="program__head d-flex align-self-center">
      <svg width="38" height="40" viewBox="0 0 38 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M32 34H6L0 40V6C0 2.7 2.7 0 6 0H32C35.3 0 38 2.7 38 6V28C38 31.3 35.3 34 32 34Z" fill="#2196F3"/>
        <path d="M17 14H21V25H17V14Z" fill="white"/>
        <path d="M19 11C20.1046 11 21 10.1046 21 9C21 7.89543 20.1046 7 19 7C17.8954 7 17 7.89543 17 9C17 10.1046 17.8954 11 19 11Z" fill="white"/>
      </svg>
      <span>Информация о секциях</span>
    </div>
    <div class="program__content">
      <slick :options="slickOptions">
        <div class="program__slide" v-for="(slide, index) in getProgram" :key="index">
          <div class="program__section">
            {{slide.programSection.name}}
            <span style="text-transform:uppercase;">{{slide.programSection.theme}}</span>
          </div>
          <div class="program__desc">Тема выступления:</div>
          <div class="program__text" v-html="slide.name"></div>
          <div class="program__speakers" v-if="slide.Speaker.length">
            <div class="program__speaker" v-for="speaker in slide.Speaker" :key="speaker.id">
              <div class="speaker__name">{{speaker.name}}</div>
              <div class="speaker__job">
                {{speaker.job}}
              </div>
            </div>
          </div>
          <div class=" text-end pt-3 d-flex align-items-center justify-content-end">
            <div class="program__link" @click="goTo(slide.section_id)">
              <span>Перейти</span>
              <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_246_1074)">
                  <circle cx="17.5" cy="16.5" r="16.5" transform="rotate(-90 17.5 16.5)" fill="#59B980"/>
                </g>
                <path d="M14.5375 24.8453L14.7624 24.8957L14.9468 24.7575L22.4422 19.1381L22.4429 19.1376C23.6039 18.2637 24.2852 17.2298 24.2852 16.1074C24.2852 14.985 23.6039 13.9512 22.443 13.0773L22.4422 13.0767L14.9468 7.45737L14.7624 7.31911L14.5375 7.36953L14.2472 7.43462L13.1888 7.67192L14.0567 8.32257L21.5517 13.9416C22.5545 14.6948 22.9553 15.4453 22.9553 16.1074C22.9553 16.7695 22.5545 17.5201 21.5517 18.2732L14.0567 23.8923L13.1888 24.5429L14.2472 24.7802L14.5375 24.8453Z" fill="white" stroke="white"/>
                <defs>
                  <filter id="filter0_d_246_1074" x="0" y="0" width="35" height="35" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="1"/>
                    <feGaussianBlur stdDeviation="0.5"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.34902 0 0 0 0 0.72549 0 0 0 0 0.501961 0 0 0 0.77 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_246_1074"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_246_1074" result="shape"/>
                  </filter>
                </defs>
              </svg>
            </div>
          </div>
        </div>
      </slick>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import _ from 'lodash';
import Slick from "vue-slick";

export default {
  name: "RaspSlider",
  components: {
    Slick
  },
  data() {
    return {
      now: null,
      interval: null,
      slickOptions: {
        slidesToShow: 1,
        infinite: true,
        arrows: false,
        dots: true,
        adaptiveHeight: true,
        autoplay: true,
        timeout: 5000,
      },
    }
  },
  computed: {
    getProgram() {
      const programs = this.$store.getters.getProgramNoFilter
      return _.sortBy(_.filter(programs, program => {
        let start = moment(program.timeStart, 'HH:mm').format('X')
        let end = moment(program.timeEnd, 'HH:mm').format('X')
        return  this.now >= start && this.now < end
      }), ['section_id'])
    }
  },
  methods: {
    goTo(section) {
      this.$router.push({
        name: 'Telecast',
        query: {
          section: section
        }
      })
    }
  },
  mounted() {
    this.now = moment.utc().format('X')
    this.interval = setInterval(() => {
      this.now = moment.utc().format('X')
    }, 1000 * 20)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
}
</script>

<style scoped lang="scss">
  .speaker {
    &__name {
      font-weight: 500;
      font-size: rem(14);
      color: #1D2130;
    }
    &__job {
      font-weight: 400;
      font-size: rem(14);
      color: #1D2130;
      opacity: 0.7;
      padding-top: rem(3);
    }
  }
  .program {
    &__content {
      padding-top: rem(20);
    }
    &__slide {
      padding: rem(20);
      background: #FFFFFF;
      border: 1px solid #A5D9BA;
      box-sizing: border-box;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
    }
    &__desc {
      font-weight: 500;
      font-size: rem(16);
      padding-top: rem(10);
      color: #282938;

    }
    &__section {
      font-style: normal;
      font-weight: 700;
      font-size: rem(21);
      color: #00476D;
    }
    &__head {
      svg {
        vertical-align: top;
      }
      span {
        vertical-align: middle;
        position: relative;
        padding-left: rem(10);
        font-weight: 400;
        font-size: rem(18);
        top: 3px;
        color: #282B2E;
      }
    }
    &__speakers {
      padding-top: rem(10);
    }
    &__speaker {
      padding-bottom: rem(6);
      &:last-child {
        padding-bottom: 0;
      }
    }
    &__link {
      cursor: pointer;
      span {
        font-weight: 200;
        font-size: rem(12);
        color: #282938;
        padding-right: rem(12);
      }
    }
    &__text {
      padding-top: rem(5);
      &::v-deep {
        * {
          font-size: rem(14);
          font-weight: 300;
        }
        p {
          margin-bottom: 0;
        }
      }
    }
  }
  .program__content {
    &::v-deep {
      .slick-dots {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        list-style-type: none;
        padding-top: rem(10);
        li {
          padding: 0 rem(2);
          button {
            min-height: 14px;
            min-width: 14px;
            border: 1px solid #59B980;
            box-sizing: border-box;
            filter: drop-shadow(0px 1px 1px rgba(89, 185, 128, 0.77));
            display: block;
            border-radius: 200px;

            background: transparent;
            font-size: 0;
          }
          &.slick-active {
            button {
              background-color: #59B980;
            }
          }
        }
      }
    }
  }
</style>
