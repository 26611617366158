<template>
  <div class="chat d-flex flex-column">
    <div class="chat__body" ref="body">
      <div class="chat__scroller d-flex flex-column  justify-content-end align-items-stretch" ref="scroll">
        <chat-message v-for="message in messages" :key="message.id" :message="message"></chat-message>
      </div>
    </div>
    <div class="chat__form d-flex justify-content-between align-items-center">
      <form @submit.prevent="sendMessage">
        <input type="text" v-model="message" placeholder="Ваше сообщение…">
        <button class="chat__button" :disabled="!message.length">
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.02954 6.98344L0.916016 0.735352L14.6804 7.57114L0.916016 14.4997L2.02954 8.22069L13.4431 7.57114L2.02954 6.98344Z" fill="white"/>
          </svg>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import ChatMessage from "./ChatMessage";
import _ from "lodash";
export default {
  name: "TelecastChat",
  components: {ChatMessage},
  props: ['room'],
  data() {
    return {
      messages: [],
      message: '',
      loading: false
    }
  },
  methods: {
    loadChat() {
      this.$axios.get('telecast/chat/' + this.room)
        .then(resp => {
          this.messages = resp.data
          this.loading = true
          setTimeout(() => {
            this.$refs.body.scrollTop = this.$refs.scroll.clientHeight;
          }, 150)
        })
        .catch(e => {
          console.log(e)
        })
      this.sockets.subscribe(`room-${this.room}`  , data => {
        if (data.type === 'add') {
          this.messages.push(data.message)
        }
        else if(data.type === 'delete') {
          const id = parseInt(data.message)
          const index = _.findIndex(this.messages, message => message.id === id)
          if (index !== -1) {
            this.messages.splice(index, 1)
          }
        }
      })
    },
    sendMessage() {
      const payload = {
        message: this.message,
        telecast_id: this.room,
        user_id: this.$store.getters.getUser.id
      }
      this.$axios.post('telecast/chat', payload)
        .then(() => {
          this.message = ''
        })
        .catch(error => {
          alert(error.response.data.message)
        })
    }
  },
  mounted() {
    this.loadChat()
  },

  watch: {
    room(newVal, oldVal) {
      this.loading = false
      this.messages = []
      this.sockets.unsubscribe(`room-${oldVal}`)
      this.loadChat()
    },
    messages() {
      setTimeout(() => {
        this.$refs.body.scrollTop = this.$refs.scroll.clientHeight;
      }, 50)
    }
  },
  beforeDestroy() {
    this.sockets.unsubscribe(`room-${this.room}`)
  }
}
</script>

<style scoped lang="scss">
  .chat {
    height: 100%;
    background: #03496E;
    box-shadow: 0px 0px 20px rgba(117, 119, 123, 0.2);
    border-radius: 4px;
    min-height: 480px;
    padding: 5px;
    max-height: 480px;
    @include media(992) {
      //margin-top: 70px;
      min-height: 320px;
      max-height: 400px;
    }
    @include media(768) {
      //margin-top: 50px;
    }
    &__body {
      width: 100%;
      height: 88%;
      background: #FFFFFF;
      box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      padding: 20px;
      overflow: auto;
      &::-webkit-scrollbar {
        //display: none;
      }
    }
    &__form {
      form {
        width: 100%;
        padding-right: 40px;
      }
      margin-top: 5px;
      height: 51px;
      background: #FFFFFF;
      box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      overflow: hidden;
      position: relative;
      width: 100%;
      input {
        width: 100%;
        border: none;
        outline: none;
        padding: 10px;
        font-size: rem(14);
        font-weight: 300;
        color: #002952;
      }
    }
    &__button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
      border: none;
      border-radius: 10em;
      background-color: #037BC2;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 33px;
      width: 33px;
      svg {
        vertical-align: middle;
      }
    }
    &__scroller {
      overflow: hidden;
      //height: 100%;
    }
  }
</style>
