<template>
  <div class="message">
    <div class="message__text" :class="isUserMessage ? 'message__text_me' : ''">
      {{message.message}}
    </div>
    <div class="message__footer d-flex justify-content-between align-items-center " :class="isUserMessage ? 'flex-row-reverse' : ''">
      <div class="message__name">{{getUserInformation.lastName}} {{getUserInformation.firstName}} <span>{{getUserInformation.organization}}</span></div>
      <div class="message__time">{{time}}</div>
    </div>
    <div class="message__admin" v-if="isAdmin">
      <button class="btn btn-danger btn-sm" @click="removeMessage" :disabled="isDeleteMessage">Удалить</button>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import _ from "lodash";
moment.locale('ru')
export default {
  name: "ChatMessage",
  props: ['message'],
  data() {
    return {
      time: null,
      interval: null
    }
  },
  computed: {
    isUserMessage() {
      return this.message.user_id === this.$store.getters.getUser.id
    },
    getUserInformation() {
      return this.message.User?.UserInfo?.information ? JSON.parse(this.message.User.UserInfo.information) : {}
    },
    isAdmin() {
      return !!_.find(this.$store.getters.getUser.Role, role => role.name === 'admin')
    },
    isDeleteMessage() {
      return this.$store.getters.getDeleteMessage
    }
  },
  mounted() {
    this.time = moment(this.message.createdAt).fromNow()
    this.interval = setInterval(() => {
      this.time = moment(this.message.createdAt).fromNow()
    }, 1000 * 60)
  },
  methods: {
    removeMessage() {
      this.$store.commit('changeDeleteMessage', true)
      this.$axios.delete(`telecast/chat/${this.message.id}`)
        .then(() => {
          this.$store.commit('changeDeleteMessage', false)
        })
        .catch(e => {
          console.log(e)
        })
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
}
</script>

<style scoped lang="scss">
  .message {
    padding-bottom: 10px;
    &:last-child {
      padding-bottom: 0;
    }
    &__text {
      background-color: #FFFBF2;
      border-radius: 5px;
      padding: 5px 10px;
      font-size: 14px;
      color: #282938;
      font-weight: 300;
      white-space: pre-wrap;
      &_me {
        background-color: #0179C0;
        color: #fff;
      }
    }
    &__footer {
      padding-top: 3px;
    }
    &__name {
      font-size: 10px;
      color: #002952;
      font-weight: 500;
      span {
        font-weight: 300;
      }
    }
    &__time {
      font-size: 10px;
      color: #D0D0D0;
      white-space: nowrap;
      font-weight: 300;
    }
  }
</style>
